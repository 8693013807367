@import "~bootstrap-icons/font/bootstrap-icons.css";
@import url("https://use.typekit.net/ueh2gwc.css");

.lato-regular {
	font-family: lato, sans-serif;
	font-weight: 400;
	font-style: normal;
}

.lato-bold {
	font-family: lato, sans-serif;
	font-weight: 700;
	font-style: normal;
}

.lato-light {
	font-family: lato, sans-serif;
	font-weight: 300;
	font-style: normal;
}

/**
Globally styles
 */

* {
	font-family: lato, sans-serif;
}

html, body {
	height: 100%;
}

body {
	margin: 0;
	padding: 0;
}

::-webkit-scrollbar, ::-webkit-scrollbar-thumb {
	height: 6px;
	width: 4px;
}

::-webkit-scrollbar-thumb {
	background: #278b4d;
}

::-webkit-scrollbar, ::-webkit-scrollbar-thumb {
	height: 6px;
	width: 4px;
}

::-webkit-scrollbar-thumb {
	background: #278b4d;
}

.scandit-logo {
	max-height: 15%;
	max-width: 15%;
	position: absolute;
	top: 2%;
	left: 5%;
	transform: translateZ(0);
	z-index: 10;
}

.scandit-laser {
	box-shadow: 0 0 0 600px rgb(0 0 0 / 45%);
	border: 5px solid #0000;
	--a: #0000 90deg, #39C1CC 0;
	--b: 40px 40px border-box no-repeat;
	background: conic-gradient(from 90deg at top 5px left 5px, var(--a)) 0 0 / var(--b),
	conic-gradient(from 180deg at top 5px right 5px, var(--a)) 100% 0 / var(--b),
	conic-gradient(from 0deg at bottom 5px left 5px, var(--a)) 0 100% / var(--b),
	conic-gradient(from -90deg at bottom 5px right 5px, var(--a)) 100% 100% / var(--b);
}

/**
Styles for the snackbar
 */

.cdk-overlay-container {
	position: fixed;
	z-index: 10000;
}

.mat-snack-bar-container {
	margin-bottom: 60px !important;
}

@media only screen and (max-width: 768px) {
	.mat-snack-bar-container {
		margin-bottom: 78px !important;
	}
}

/**
Styles for the header title
 */

.header-container {
	max-width: 100%;
	min-height: 100%;
	display: flex;
	margin: 50px 0 0 0;
	position: sticky;
	top: 70px;
	background-color: #FFFFFF;
}

.icon-title {
	font-size: 35px;
	margin-right: 15px;
	color: #278b4d;;
}

.card-title {
	margin-bottom: 1.75rem;
}

/**
Button Styles
 */

.primary-btn:focus {
	outline: none !important;
	box-shadow: none;
	text-decoration: none;
}

.primary-btn {
	background-color: #278b4d;
	color: #ffffff;
	height: 40px;
}

.secondary-btn {
	background-color: transparent;
	border-color: #278b4d;
	text-transform: unset;
	text-decoration: none;
	color: #278b4d;
	height: 40px;
}

.primary-btn:active, .secondary-btn:active {
	transform: scale(0.98);
	box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

/**
Styles for quantity input field
 */

.quantity-input {
	text-align: center;
	height: 40px;
	font-size: 14px;
}

.quantity-btn {
	background-color: #278b4d;
	color: #ffffff;
	height: 40px;
}

.glyphicon::before {
	font-size: 15px;
}

.form-control:focus {
	border-color: #28a745;
	box-shadow: 0 0 0 0.1rem rgba(40, 167, 69, 0.25);
}

/**
Modal customizing
 */

.modal-footer {
	justify-content: space-around;
	padding: 0.75rem;
}

.modal-footer--sticky {
	position: sticky;
	bottom: 0;
	background-color: #FFFFFF;
	z-index: 1055;
}

.modal-header--sticky {
	position: sticky;
	top: 0;
	background-color: #FFFFFF;
	z-index: 1055;
}

/**
Sticky element below header. In other words highlighted component
 */
.heading-container--stycky {
	position: sticky;
	top: 51px;
	background-color: #FFFFFF;
	z-index: 1055;
}
